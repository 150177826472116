<template>
    <div>
        <div class="manage-box">
            <div class="manage-left" v-loading="leftLoading">
                <div class="padding-sm" style="overflow:hidden;">
                    <el-button class="fl-r" type="primary" size="mini" @click="setClass">新增小节</el-button>
                </div>
                <div>
                    <el-table :data="classList" style="width: 100%">
                        <el-table-column prop="id" label="id">
                        </el-table-column>
                        <el-table-column prop="name" label="名称">
                        </el-table-column>
                        <el-table-column prop="size" label="大小">
                            <template slot-scope="scope">
                                <span>{{unitConversion(scope.row.size)}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sort" label="排序">
                        </el-table-column>
                        <el-table-column label="试听" property="pic" align="center">
                            <template slot-scope="scope">
                                <el-tag size="mini">{{scope.row.unlock == 1?'是':'否'}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-link type="primary" :underline="false" @click.stop="handleEdit(scope.row)">编辑
                                </el-link> -
                                <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                                    @confirm="handleDelete(scope.row)">
                                    <el-link slot="reference" type="primary" @click.stop>删除</el-link>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>

            <div class="manage-right" v-loading="planLoading">
                <div class="padding-sm" style="overflow: hidden">
                    <el-button class="fl-r" type="primary" size="mini" @click="add">新增章节</el-button>
                </div>
                <div v-if="showSetPlan" class="padding-sm">

                    <el-input size="mini" placeholder="请输入名称" v-model="plan.name" clearable>
                    </el-input>

                    <el-input size="mini" placeholder="排序" v-model="plan.sort" clearable>
                    </el-input>








                    <div class="margin-t-sm">
                        <el-button size="mini" type="primary" :loading="setPlanLoading" @click="setPlanSubmit">确定
                        </el-button>
                        <el-button size="mini" @click="hideSetPlanInput">取消</el-button>
                    </div>
                </div>
                <div class="plan-box">
                    <el-tree ref="tree" :indent="5" :data="chapters" accordion highlight-current :current-node-key="id"
                        :default-checked-keys="[id]" @node-click="selectPlan" node-key="id">
                        <span class="custom-tree-node" slot-scope="{ node, data }">
                            <span>{{ node.name }} </span>
                            {{data.name}}
                            <div style="margin-top:5px">
                                <el-link type="primary" :underline="false" @click.stop="editPlan(data)">编辑</el-link>
                                -
                                <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                                    @confirm="delPlan(data)">
                                    <el-link slot="reference" type="primary" @click.stop>删除</el-link>
                                </el-popconfirm>
                            </div>
                        </span>
                    </el-tree>
                </div>
            </div>
            <div class="clear"></div>
        </div>

    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {

    data() {
        return {
            classList: [],
            showSetPlan: false,
            leftLoading: false,
            planLoading: false,
            setPlanLoading: false,
            plan: {},
            chapters: [],
            id: 0
        }
    },
    methods: {
        ...mapActions('hearBook', ['getDetailChapter', 'addAudioBooksChapter', 'getAudioBooksDetail', 'getAudioBooksChapter', 'delAudioBooksChapter', 'delAudioBooksSection']),
        add() {
            this.showSetPlan = true
        },
        unitConversion(size) {
            let kb = size
            return (kb / 1024 / 1024).toFixed(2) + 'M'
        },
        hideSetPlanInput() {
            this.showSetPlan = false
        },
        async setPlanSubmit() {
            this.setPlanLoading = true
            let form = {
                audio_books_id: this.$route.params.id,
                ...this.plan
            }
            const { data, res_info } = await this.addAudioBooksChapter(form)
            await this.initData()
            this.setPlanLoading = false
            this.showSetPlan = false
            if (res_info != 'ok') return
            this.$message.success('创建成功')
            this.id = data
            this.getClass()
        },
        async initData() {
            this.planLoading = true
            const { data } = await this.getAudioBooksDetail(this.$route.params.id)
            this.chapters = data.chapters
            if (this.chapters.length > 0 && this.id === 0) {
                this.id = data.chapters[0].id
                this.$nextTick(() => {
                    this.$refs['tree'].setCurrentKey(this.id)
                })
            }
            if (this.id !== 0) await this.getClass()
            this.planLoading = false
        },
        handleEdit(data) {
            this.$router.push('/audioBooks/detailClass/' + this.id + '/' + data.id)
        },
        async handleDelete(row) {
            const { data, res_info } = await this.delAudioBooksSection(row.id)
            if (res_info != 'ok') return
            this.$message.success('创建成功')
            this.getClass()
        },
        setClass() {
            if (this.chapters.length === 0) {
                this.$message.error('请先章节计划')
                return
            }
            this.$router.push('/audioBooks/addClass/' + this.id)
        },
        async delPlan(node) {
            let id = node.id
            const { data, res_info } = await this.delAudioBooksChapter(id)
            await this.initData()
            if (res_info != 'ok') return
            this.$message.success('删除成功')
        },
        async editPlan(node) {
            let id = node.id
            const { data } = await this.getAudioBooksChapter(id)
            this.showSetPlan = true
            this.plan = data
        },
        selectPlan(e) {
            this.id = e.id
            this.getClass()
        },
        async getClass() {
            this.leftLoading = true
            const { data } = await this.getDetailChapter(this.id)
            this.classList = data.sections
            this.leftLoading = false
            this.$nextTick(() => {
                this.$refs['tree'].setCurrentKey(this.id)
            })
        },

    },
    mounted() {
        this.initData()
    },
}
</script>

<style >
.table-header {
    padding: 15px;
    background: #fafafa;
}

.font-size-12 {
    font-size: 12px;
}

.circle {
    border: 1px solid #ccc;
    padding: 3px;
    border-radius: 50%;
    margin-right: 3px;
    font-size: 8px;
}

.section {
    font-size: 14px;
    padding: 15px;
    display: flex;
    align-items: center;
}

.border-s {
    border-bottom: 1px solid #ebebeb
}

.w10 {
    width: 20%;
}

.w26 {
    width: 20%;
}

.manage-left {
    float: left;
    width: 70%;
    height: 100%;
}

.manage-right {
    border-left: 1px solid #ccc;
    float: right;
    width: 29%;
    height: 700px;
}

.plan-box {
    height: 600px;
    overflow: auto;
    padding: 10px
}

.plan-item {
    padding: 10px 5px;
    cursor: pointer;
}

.plan-item:hover {
    background: #EBEEF5;
}

.current {
    background: #F2F6FC;
}

.item-name {
    line-height: 24px;
}

.el-tree-node__content {
    height: auto;
    padding: 5px 0;
}
</style>